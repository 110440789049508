export default async function <T>(
  key: string,
  cb: () => Promise<T>,
  onError?: (e: Error) => void
) {
  onMounted(() => {
    if (
      asyncData.data.value === 'undefined' &&
      (!asyncData.error || !(asyncData.error instanceof Error))
    ) {
      asyncData.execute().catch((error: Error) => {
        nextTick(() => {
          if (onError) {
            onError(error);
          }
        });
      });
    }
  });

  const asyncData = await useAsyncData(
    key,
    async () => {
      return (await cb()) || ('null' as const);
    },
    {
      immediate: false,
      default: () => 'undefined' as const
    }
  );

  if (!process.client) {
    await asyncData.execute();
  }

  if (
    asyncData.error?.value &&
    asyncData.error.value instanceof Error &&
    onError
  ) {
    onError(asyncData.error.value);
  }

  return {
    ...asyncData,
    data: computed(() => {
      return asyncData.data.value === 'null'
        ? null
        : asyncData.data.value === 'undefined'
        ? undefined
        : (asyncData.data.value as T);
    })
  };
}
